(function( $, window ) {
    'use strict';

    window.WpAjax = (function () {

        var wp_ajax_url = '/wp-admin/admin-ajax.php';

        function WpAjax () {

            //constructor
        }

        function getRequest (action, data, callback) {

            $.get(
                wp_ajax_url,
                {
                    'action': action,
                    'data': data
                },
                function(response) {

                    callback(response);
                }
            );
        }

        WpAjax.prototype.getEntitiesList = function(widgetInstanceId, callback) {

            var action = 'pmwpp_list_entities',
                data = {
                    'widget': widgetInstanceId
                };

            getRequest (action, data, callback);
        };

        return WpAjax;
    }());

})( jQuery, window );