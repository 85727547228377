(function( $, window ) {
    'use strict';

    window.CallToAction = (function () {

        function CallToAction () {

            //constructor
        }

        CallToAction.prototype.getURL = function( $element ) {

            return $element.data('endpoint');
        };

        return CallToAction;
    }());

})( jQuery, window );