(function( $, window ) {
    'use strict';

    window.ListView = (function () {

        var anchorAttribute = 'href',
            pageClass = 'page',
            openedClass = 'opened',
            closedClass = 'closed',
            onClass = 'on',
            offClass = 'off',
            paginationContainerClass = 'pmwpp-list-pagination',
            detailsSelector = '.details',
            anchorSelector = '[' + anchorAttribute + ']',
            nameSelector = '.name',
            lastnameSelector = '.lastname',
            initialsSelector = '.initials',
            instanceIdSelector = '#instanceId',
            listSpinnerSelector = '.list_spinner',
            articlesSelector = '.pm-list-item',
            paginationContainerSelector = '.' + paginationContainerClass,
            pagesSelector = '.' + pageClass,
            imageProtocol = 'http://';

        function ListView ($widgetContainer, itemsPerPage) {

            this.itemsPerPage = itemsPerPage;
            this.$widgetContainer = $widgetContainer;
            this.$htmlContent = null;
        }

        ListView.prototype.setContent = function ($htmlContent) {

            if ($htmlContent instanceof $) {

                this.$htmlContent = $htmlContent;
            }
        };

        ListView.prototype.getWidgetId = function () {

            var result = false;

            if (this.$widgetContainer instanceof $) {

                result = this.$widgetContainer.find(instanceIdSelector).val();
            }

            return result;
        };

        ListView.prototype.toggleSpinner = function () {

            if (this.$widgetContainer instanceof $) {

                if ( this.$widgetContainer.find( listSpinnerSelector ).hasClass( onClass ) ) {

                    this.$widgetContainer.find( listSpinnerSelector ).removeClass( onClass ).addClass( offClass );
                }
                else {

                    this.$widgetContainer.find( listSpinnerSelector ).removeClass( offClass ).addClass( onClass );
                }
            }
        };

        ListView.prototype.toggleDetailsOnListItem = function ($element) {

            if ($element.hasClass( openedClass )) {

                $element.removeClass( openedClass ).addClass( closedClass );
                $element.parent().siblings( detailsSelector ).removeClass( openedClass ).addClass( closedClass );
            }
            else {

                $element.removeClass( closedClass ).addClass( openedClass );
                $element.parent().siblings( detailsSelector ).removeClass( closedClass ).addClass( openedClass );
            }
        };

        ListView.prototype.displayPagination = function () {

            if (this.$htmlContent instanceof $ && this.$widgetContainer instanceof $) {

                var self = this,
                    pages = self.$htmlContent.length;

                this.$widgetContainer.append('<div class="' + paginationContainerClass + '"></div>');

                if (pages > 1) {

                    self.$widgetContainer.find( paginationContainerSelector ).pagination({

                        pages: pages,
                        itemsOnPage: self.itemsPerPage,
                        displayedPages: 3,
                        edges: 1,
                        prevText: ' ',
                        nextText: ' ',
                        onPageClick: function(pageNumber, event) {

                            if (event != undefined) {

                                event.preventDefault();
                            }

                            self.displayPage( pageNumber, true );
                        }
                    });
                }
                self.displayPage( 1 );
            }
        };

        ListView.prototype.displayPage = function (pageNumber, repositionate) {

            if (this.$widgetContainer instanceof $) {

                var currentPageIndex = pageNumber - 1,
                    $currentPage = this.$htmlContent.eq( currentPageIndex ).clone(),
                    $paginationContainer = this.$widgetContainer.find( paginationContainerSelector ),
                    paginationTopPositionOffset;

                $currentPage = this.fixLinks( $currentPage );
                $currentPage = this.displayInitials( $currentPage );

                this.$widgetContainer.find( articlesSelector ).remove();
                $paginationContainer.before( $currentPage );

                if (repositionate) {

                    paginationTopPositionOffset = $paginationContainer.offset().top;
                    $('body').scrollTop(
                        paginationTopPositionOffset - (200 * 3) //Three times de height of the item
                    );
                }
            }
        };

        ListView.prototype.displayInitials = function ($thisContainer) {

            if ($thisContainer instanceof $) {

                for (var i = 0; i < $thisContainer.find(articlesSelector).length; i++) {

                    var $article = $thisContainer.find(articlesSelector).eq(i),
                        nameInitial = '',
                        lastnameInitial = '';

                    if ($article.find(nameSelector).length > 0) {
                        nameInitial = $article.find(nameSelector).text().trim().charAt(0);
                    }
                    if ($article.find(lastnameSelector).length > 0) {
                        lastnameInitial = $article.find(lastnameSelector).text().trim().charAt(0);
                    }
                    $article.find(initialsSelector).html(nameInitial.toUpperCase() + lastnameInitial.toUpperCase());
                }
            }

            return $thisContainer;
        };

        ListView.prototype.fixLinks = function ($thisContainer) {

            if ($thisContainer instanceof $) {

                var $articleLinks = $thisContainer.find( articlesSelector + ' ' + anchorSelector );

                $articleLinks.each( function() {

                    var thisLink = $(this).attr( anchorAttribute );

                    if (thisLink != undefined && thisLink != '' && thisLink != '#' && thisLink.indexOf( imageProtocol ) == -1 ) {

                        $(this).attr( anchorAttribute, imageProtocol + thisLink );
                    }
                });
            }

            return $thisContainer;
        };

        return ListView;
    }());

})( jQuery, window );