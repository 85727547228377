(function( $, window ) {
	'use strict';

    $( document ).ready(function() {

        var listView = new window.ListView(),
            callToAction = new window.CallToAction(),
            wpAjax =  new window.WpAjax();

        $('.widget_list_entities').each( function() {

            var $listViewContainer = $(this),
                listItemsField = $listViewContainer.find("#listItems"),
                listItems = listItemsField.length == 1 ? parseInt( listItemsField.val() ) : 10,
                thisListView = new window.ListView( $listViewContainer, listItems );

            thisListView.toggleSpinner();

            wpAjax.getEntitiesList( thisListView.getWidgetId(), function(response) {

                thisListView.setContent( $(response) );
                thisListView.displayPagination();
                thisListView.toggleSpinner();
            });

        });

        $('body').on('click', '.pm-list-item .visibility', function(event) {

            event.preventDefault();

            listView.toggleDetailsOnListItem( $(this) );
        });

        $('body').on('click', '.pm-cta .cta', function(event) {

            event.preventDefault();
            window.open( callToAction.getURL( $(this) ), '_blank');
        });

    });

})( jQuery, window );